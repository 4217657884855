import type { AdDetails } from '../../@types/adCommon';
import type { SafeFrameClient, WriteAdHandler } from '../components/safeFrame';
import { produce, produceViewableInfo } from '../components/sfAPI';
import { CommonApiImplementation } from './CommonApiImplementation';
import { ClientMessageListeners, ClientMessageReceiver } from './clientMessageReceiver';
import { ClientMessageSender } from './clientMessageSender';
import { disableCookieAccess, disableGeolocationApi, sendCriticalFeatureAndLoaded } from './commonSf';
import * as AD_LOAD_EVENTS from '../components/events/AD_LOAD_EVENTS';
import { IFRAME_INIT } from '../components/counters/AD_LOAD_COUNTERS';
import { BODY_END } from '../components/metrics/latency-metric-type';
import { ABP_STATUS, shouldRenderVideoPlayer } from '../components/util';
import { documentWrite, postCreativeWrite, tagRenderFlow } from './render';
import { ClientApis } from './types/types';
import type { CommonSupportedCommands } from '../host/CommonSupportedCommands';
import { logDebug } from '@amzn/apejs-instrumentation/src/metrics/logging';
import { replace } from '@amzn/apejs-click-tracking';
import { handleVideoExperience, type SetupVideoPlayer } from './video';
import { ADPT_SF_ADSP_VIDEO_833419 } from '../components/weblabs';
import { htmlProvider } from './domain/htmlProviderFactory';
import type { HostMessageToClient } from '../components/messaging/types';
import { appendBlankAdDetectionScript } from './blankAds.util';

declare global {
    interface Window {
        aaxInstrPixelUrl: any;
    }
}

export const DEFAULT_PERCOLATE_TIMEOUT = 500;

export type CommonSFClientSetupState = {
    hasCODFallbackRegistered: boolean;
    hasFiredCODPixel: boolean;
};

export type InitSFClientOptions = {
    timeout?: number;
};

export abstract class CommonSFClientSetup<ActualApiImplementation extends CommonApiImplementation>
    implements SafeFrameClient
{
    readonly nativeWrite: typeof document.write;
    readonly nativeOpen: typeof document.open;
    readonly cmr: ClientMessageReceiver;
    renderCompleteTime: Date | null = null;
    state: CommonSFClientSetupState = {
        hasCODFallbackRegistered: false,
        hasFiredCODPixel: false,
    };

    private clickTrackingParam = ''; // Containers the clickTrackingParam passed from the host

    protected constructor(
        protected readonly o: AdDetails,
        protected readonly mp: MessagePort,
        protected readonly mL: ClientMessageListeners,
        protected readonly cms: ClientMessageSender,
        readonly c: ActualApiImplementation,
        protected readonly setupVideoPlayer?: SetupVideoPlayer,
    ) {
        // TODO Sort out this ClientApi coercion due to the dynamic type dispatch
        this.cmr = new ClientMessageReceiver(c as unknown as ClientApis, o, this.mL);
        this.nativeWrite = document.write.bind(document);
        this.nativeOpen = document.open.bind(document);
        window.onerror = this.handleErrors;
    }

    initSFClient = async (opts: InitSFClientOptions = {}) => {
        const { timeout = DEFAULT_PERCOLATE_TIMEOUT } = opts;

        this.ensureGlobals();
        /* Disable creative access to set and get cookies: https://issues.amazon.com/issues/APEX-4377 */
        disableCookieAccess();
        /* Disable geolocation API: https://issues.amazon.com/issues/CPP-24902 */
        disableGeolocationApi();

        /*
         * Called by the creative for further mutations to the DOM
         */
        document.write = this.documentWriteDelegate;
        /*
         * Override document.open so we can detect future writes and ensure globals are set
         */
        document.open = ((...args: any[]) => {
            this.nativeOpen(...args);
            this.ensureGlobals();
        }) as typeof document.open;

        // Send clientBodyEnd after setting up the safeframe APIs
        this.c.sendLatencyMetric(BODY_END);
        this.cms.sendMessage<CommonSupportedCommands['safeFrameReady']>('safeFrameReady');
        // Wait for the Percolate Click Tracking To Come Back
        this.clickTrackingParam = await waitForPercolateClickTrackingFromHost(this.mp, timeout);
        // We don't want to start processing messages in the host message receiver until we have gotten the
        // percolate click tracking param
        this.mp.onmessage = this.cmr.receiveMessage;

        this.c.countMetric(IFRAME_INIT, 1);
        this.c.logCsaEvent(AD_LOAD_EVENTS.IFRAME_INIT);

        // The purpose of this function is to defer writing until the SFClient variable is set up
        // before the write ad html code starts loading
        window.writeAdHandler = await this.setupWriteAdHandler();

        await window.writeAdHandler();
    };

    // We expect the synthetic load event to be fired during this code path
    private readonly setupWriteAdHandler = async (): Promise<WriteAdHandler> => {
        addSyntheticOnLoadListeners(this, this.o);

        if (abpStatusFallbackEnabled(this.o)) {
            return async () => {
                generateSyntheticLoadEvent();
                this.forceRenderFallbackExperience();
            };
        }

        try {
            const ad = await htmlProvider(this.o, this.c).getAd();
            const creative = ad.creative;

            if (!creative) {
                return async () => {
                    generateSyntheticLoadEvent();
                    this.forceRenderFallbackExperience();
                };
            }

            this.o.htmlContent = creative;
            // TODO: Below fields will be coming from ASPEN in late Q1. At this point they should be removed.
            this.o.aaxInstrPixelUrl = ad.instrumentationPixelUrl ?? this.o.aaxInstrPixelUrl;
            this.o.aaxImpPixelUrl = ad.impressionPixelUrl ?? this.o.aaxImpPixelUrl;
            this.o.adCreativeMetaData.adImpressionId = this.o.aaxImpPixelUrl;
            this.o.adCreativeMetaData.adCreativeTemplateName =
                ad.creativeTemplateName ?? this.o.adCreativeMetaData.adCreativeTemplateName;
            return async () => this.loadAd(creative);
        } catch (err) {
            this.c.logError("Couldn't getAd from htmlProvider", err as Error);
            return async () => {
                generateSyntheticLoadEvent();
                this.forceRenderFallbackExperience();
            };
        }
    };

    ensureGlobals = () => {
        window.onerror = this.handleErrors;
        window.$sf = produce();
        window.$sfViewableInfo = produceViewableInfo();
        window.aaxInstrPixelUrl = this.o.aaxInstrPixelUrl;
    };

    /**
     * This method can be called multiple times during the lifecycle
     */
    documentWriteDelegate = async (htmlContent: string): Promise<void> => {
        await documentWrite(htmlContent, this.state, this, this.o, this.cms, this.c.cr, this.c);
    };

    /* Overwrite the window's onerror method to catch any unexpected errors
     * coming from the creative code. We will log the error and attempt to
     * show a different ad or collapse the slot.
     */
    private handleErrors: OnErrorEventHandler = (
        message: Event | string,
        _source?: string,
        _lineno?: number,
        _colno?: number,
        errorObject?: Error,
    ): boolean => {
        const err = errorObject || new Error(message.toString());
        this.c.logError('Window.onerror', err);

        if (!this.renderCompleteTime) {
            this.forceRenderFallbackExperience();
        }

        // Returning true suppresses the default browser behavior
        return true;
    };

    /**
     * @desc Forces fallback experience to render for the ad slot, to preserve CX when an ad cannot be rendered properly
     */
    forceRenderFallbackExperience = () => {
        tagRenderFlow(this.c, this.o, this.cms);

        const fallbackStaticImageConfigured = this.o.fallbackStaticAdImgUrl && this.o.fallbackStaticAdClickUrl;
        if (fallbackStaticImageConfigured) {
            this.renderFallbackStaticAd(
                this.o.fallbackStaticAdImgUrl,
                this.o.fallbackStaticAdClickUrl,
                this.o.fallbackStaticAdExtraStyle,
            );
            this.renderCompleteTime = new Date();
        } else {
            this.c.collapseSlotInternal();
        }
        this.c.handleFallbackExperience();
    };

    private renderFallbackStaticAd = (imgUrl: string, clickUrl: string, style: string): void => {
        const createImage = (imgUrl: string, style: string) => {
            const setFallbackImageStyle = (img: HTMLImageElement, style: string) => {
                style.split(' ').forEach((x) => {
                    const attribute = x.split('=');
                    img.setAttribute(attribute[0], attribute[1]);
                });
            };

            const img = document.createElement('img');
            img.src = imgUrl;
            img.id = 'static-fallback-img';
            setFallbackImageStyle(img, style);
            return img;
        };

        const a = document.createElement('a');
        a.target = '_top';
        a.href = clickUrl;
        a.append(createImage(imgUrl, style));

        document.body.replaceChildren();
        document.body.append(a);

        this.renderFallbackStaticAdHelper();

        this.ensureGlobals();
        this.mp.onmessage = this.cmr.receiveMessage;
    };

    protected renderFallbackStaticAdHelper() {
        //
    }

    /**
     * This should only be called ever once
     */
    loadAd = async (creative: string): Promise<void> => {
        this.o.isNoInventory = false;
        tagRenderFlow(this.c, this.o, this.cms);
        let htmlContent = replace(this.clickTrackingParam, creative);
        if (this.isVideo()) {
            if (ADPT_SF_ADSP_VIDEO_833419().isT1()) {
                handleVideoExperience(this.o, this.c, htmlContent, this.setupVideoPlayer as SetupVideoPlayer);
            } else {
                this.c.handleFallbackBehavior();
            }
        } else {
            htmlContent = this.loadAdPlatformSpecific(htmlContent);
            await this.documentWriteDelegate(htmlContent);
        }
        await postCreativeWrite(htmlContent, this.o, this.cms, this.c.cr, this.c);
        appendBlankAdDetectionScript(this.o, this.c);
    };

    private isVideo = (): boolean => shouldRenderVideoPlayer(this.o.mediaType, this.o.adCreativeMetaData.adProgramId);

    protected loadAdPlatformSpecific(htmlContent: string): string {
        return htmlContent;
    }
}

const abpStatusFallbackEnabled = (adDetails: AdDetails): boolean => {
    return !!(
        adDetails.abpAcceptable !== 'true' &&
        adDetails.abpStatus &&
        adDetails.enableFallbackForAbpStatuses &&
        adDetails.enableFallbackForAbpStatuses.indexOf(ABP_STATUS[adDetails.abpStatus]) > -1
    );
};

/**
 *
 * @deprecated Migrate the logic to the GPAT side so we don't need to ask the wrapper.ts
 */
export const waitForPercolateClickTrackingFromHost = async (
    mp: MessagePort,
    timeout = DEFAULT_PERCOLATE_TIMEOUT,
): Promise<string | ''> => {
    // Can't use race here because we need to remove the event listener
    return new Promise<string>((r) => {
        let timeoutId = -1;
        const listener = (e: MessageEvent) => {
            const command = e.data as HostMessageToClient;
            const expectedCommand = 'percolateClickTracking';
            if (command.command !== expectedCommand)
                throw new Error(`Unexpected command ${command}, Expected ${expectedCommand}`);
            window.clearTimeout(timeoutId); // We don't want to fire the trigger now
            r(command.data);
        };
        // TODO Remove this after we have made sure it works
        timeoutId = window.setTimeout(() => {
            mp.removeEventListener('message', listener); // Don't want to process the next message as it won't be what we expect!
            r('');
            logDebug(`Did not receive percolateClickTracking within ${timeout}ms`);
        }, timeout) as number;
        mp.addEventListener('message', listener, { once: true });
        mp.start();
    });
};

// We generate our own synthetic load event for the creative to hook (since the initial sf script and html already fired it)
// we expect a matching call to generateSyntheticLoadEvent to be generated
const addSyntheticOnLoadListeners = (client: CommonSFClientSetup<any>, adDetails: AdDetails) => {
    if (!adDetails.creativeSupportsTTIMeasurement) {
        window.addEventListener('load', () => sendCriticalFeatureAndLoaded(client.c), { once: true });
    }
    window.addEventListener('load', () => client.c.fireViewableLatencyMetrics(), { once: true });
};
export const generateSyntheticLoadEvent = () => {
    window.dispatchEvent(new Event('load'));
};
